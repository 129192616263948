import {V0ToV1} from './MigrationFunctions';
import { PreferencesMigrator } from './Migrator';

export const TAB_PREFERENCES_VERSION = 1;


const tabPreferencesMigratorInstance = new PreferencesMigrator();

// For new migration:
// - Add a migration function in MigrationFunction.js file
// - Add the migration to the migrator like tabPreferencesMigrator.addMigration(1, V0ToV1)
// - Update the CURRENT_TAB_PREFERENCES_VERSION to your new version
// MIGRATION VERSION IS A NUMBER NOT STRING

tabPreferencesMigratorInstance.addMigration(1, V0ToV1);
/* eslint-disable-next-line */

export { tabPreferencesMigratorInstance };
