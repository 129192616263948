import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Badge, Dropdown, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import DraggableTabs from '../draggable-tabs/DraggableTabs';
import ViewTab from './ViewTab';
import useViewManagement from '../../hooks/useViewManagement';
import TabContainer from '../draggable-tabs/TabContainer';
import authService from '../../services/auth.service';
import { notificationSuccess, requestError } from '../../helpers/notification';
import ShareViewModal from './ShareViewModal';

const { TabPane } = Tabs;

const ViewTabs = ({
    currentViews,
    activeTab,
    handleNewTabOrder,
    handleChangeView,
    viewsChoiceRender,
    addingNewView,
    sharedTabs,
    isSingleTab,
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'planning_tabs' });

    const ShareViewModalRef = useRef();

    const [shownViewIds, setShownViewIds] = useState([]);

    const handleShareViewClick = useCallback(() => {
        ShareViewModalRef.current.openModal();
    }, [ShareViewModalRef]);

    const handleShareView = async ({ teamId }) => {
        try {
            await authService.sharePlanningTab(activeTab.id, teamId);
            notificationSuccess(t('share_view'), t('share_view_success'));
        } catch (error) {
            requestError(error, t('share_view'));
        }
    };

    const { editViewKey, handleChangeViewName, handleContextMenuClick } = useViewManagement(
        shownViewIds,
        setShownViewIds,
        handleShareViewClick
    );

    const renderAddIcon = useMemo(
        () => (
            <Dropdown overlay={viewsChoiceRender} trigger={['click']} placement="bottomLeft">
                <Badge count={sharedTabs.length} size="small" offset={[0, 7]}>
                    <PlusOutlined spin={addingNewView} style={{ padding: '10px' }} />
                </Badge>
            </Dropdown>
        ),
        [viewsChoiceRender, sharedTabs.length, addingNewView]
    );

    const renderViewTab = useCallback(
        (view) => (
            <TabPane
                key={view.id.toString()}
                tab={
                    <ViewTab
                        view={view}
                        activeTab={activeTab}
                        editViewKey={editViewKey}
                        onContextMenuClick={handleContextMenuClick}
                        onChangeViewName={handleChangeViewName}
                    />
                }
            />
        ),
        [activeTab, editViewKey, handleContextMenuClick, handleChangeViewName]
    );
    return (
        <>
            <TabContainer>
                <DraggableTabs
                    type="editable-card"
                    hideAdd={isSingleTab}
                    size="small"
                    onNewOrder={handleNewTabOrder}
                    addIcon={renderAddIcon}
                    activeKey={activeTab?.id.toString()}
                    onChange={handleChangeView}
                >
                    {currentViews.map(renderViewTab)}
                </DraggableTabs>
            </TabContainer>
            {activeTab && <ShareViewModal activeView={activeTab} onOk={handleShareView} ref={ShareViewModalRef} />}
        </>
    );
};

ViewTabs.propTypes = {
    currentViews: PropTypes.array.isRequired,
    activeTab: PropTypes.object,
    handleNewTabOrder: PropTypes.func.isRequired,
    handleChangeView: PropTypes.func.isRequired,
    viewsChoiceRender: PropTypes.node.isRequired,
    addingNewView: PropTypes.bool.isRequired,
    sharedTabs: PropTypes.array.isRequired,
    isSingleTab: PropTypes.bool.isRequired,
};
ViewTabs.defaultProps = {
    activeTab: null,
};

export default React.memo(ViewTabs);
