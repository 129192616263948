import { DATE_FORMAT_EN, DATE_FORMAT_FR } from "../../../constants/Generic";

const computeDateFormat = (preferences) => {
    if (preferences.dateFormat) {
        window.dateStringFormat = preferences.dateFormat.replaceAll('_', preferences.dateSeparator);
        window.datepickerFormat = window.dateFormat.convert(
            window.dateStringFormat,
            window.dateFormat.momentJs,
            window.dateFormat.datepicker
        );
    } else {
        switch (localStorage.getItem('lng')) {
            case 'en':
                window.dateStringFormat = DATE_FORMAT_EN.replaceAll('_', '-');
                break;
            case 'fr':
                window.dateStringFormat = DATE_FORMAT_FR.replaceAll('_', '/');

                break;

            default:
                window.dateStringFormat = DATE_FORMAT_FR.replaceAll('_', '/');

                break;
        }
        window.datepickerFormat = window.dateFormat.convert(
            window.dateStringFormat,
            window.dateFormat.momentJs,
            window.dateFormat.datepicker
        );
    }
};
export default computeDateFormat;