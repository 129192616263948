import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu, Typography } from 'antd';
import {
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    LinkOutlined,
    ShareAltOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ViewTab = ({ view, activeTab, editViewKey, onContextMenuClick, onChangeViewName }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'planning_tabs' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;

    const isActive = +activeTab?.id === +view?.id;

    const handleChange = (newName) => {
        onChangeViewName(newName, view?.id);
    };
    const TabsMenu = [
        {
            label: t('edit_view_name'),
            key: 'edit-view-name',
            icon: <EditOutlined />,
        },
        {
            label: t('open_view_standalone'),
            key: 'open-view-outside',
            icon: <LinkOutlined />,
        },
        {
            label: t('duplicate_view'),
            key: 'duplicate-view',
            icon: <CopyOutlined />,
        },
        {
            label: t('hide_view'),
            key: 'hide-view',
            icon: <EyeInvisibleOutlined />,
        },
        {
            label: generalTranslation('share'),
            key: 'share-view',
            icon: <ShareAltOutlined />,
        },
        {
            label: t('delete_view'),
            key: 'delete-view',
            icon: <DeleteOutlined />,
        },
    ];
    const tabContent = (
        <div className="flex items-center justify-between">
            <Text
                key={`editor${view?.id}`}
                type="primary"
                onKeyDown={(e) => e.stopPropagation()}
                className="mr-2"
                editable={{
                    editing: editViewKey === view?.id,
                    tooltip: false,
                    triggerType: ['text'],
                    enterIcon: null,
                    onKeyDown: (e) => e.stopPropagation(),
                    onChange: handleChange,
                }}
            >
                {view.name}
            </Text>
            {isActive && <DownOutlined />}
        </div>
    );

    return isActive ? (
        <Dropdown
            overlay={<Menu onClick={({ key }) => onContextMenuClick(key, view)} items={TabsMenu} />}
            trigger={['click']}
        >
            {tabContent}
        </Dropdown>
    ) : (
        tabContent
    );
};

ViewTab.propTypes = {
    view: PropTypes.object.isRequired,
    activeTab: PropTypes.object,
    editViewKey: PropTypes.string,
    onContextMenuClick: PropTypes.func.isRequired,
    onChangeViewName: PropTypes.func.isRequired,
};

ViewTab.defaultProps = {
    activeTab: null,
    editViewKey: null,
};

export default React.memo(ViewTab);
