import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Alert, Button, Col, Form, Row } from 'antd';
import TeamService from '../../services/team.service';

const TeamDeleteForm = ({ team, showList }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const submitForm = async () => {
        setLoading(true);
        setError('');
        try {
            await TeamService.deleteTeam(team.id);
            setLoading(false);
            showList();
        } catch (requestError) {
            setLoading(false);
            setError("Erreur lors de la suppression de l'équipe");
        }
    };

    return (
        <Form name="team_remove_form" onFinish={submitForm}>
            <p>Êtes-vous sûr de vouloir supprimer l&apos;équipe {team.name} ?</p>

            {error && <Alert message="Erreur" description={error} type="error" showIcon />}

            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={showList}>
                        Annuler
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Enregistrer
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

TeamDeleteForm.propTypes = {
    team: PropTypes.object.isRequired,
    showList: PropTypes.func.isRequired,
};

export default TeamDeleteForm;
