import React, { forwardRef, useImperativeHandle, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Form, List, Modal, Row } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import authService from '../../services/auth.service';
import { requestError } from '../../helpers/notification';
import { isSameFilter } from '../filters/FilterUtils';
import { selectPlanningSelected } from '../../redux/slices/app/planning.slice';
import { fetchPlanningTabs, fetchSharedPlanningTabs, selectSharedTabs } from '../../redux/slices/app/tab.slice';
import { selectUserPreferences, updateUserPreferences } from '../../redux/slices/app/user.slice';

const SharedViewModal = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isVisible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const sharedTabs = useSelector(selectSharedTabs);
    const userPreferences = useSelector(selectUserPreferences);
    const planningSelected = useSelector(selectPlanningSelected);

    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
        openModal() {
            setVisible(true);
        },
    }));

    const onCancel = () => {
        setVisible(false);
        form.resetFields();
    };

    const handleViewFilterData = useCallback(
        (rawData) => {
            const userFilterLibrary = userPreferences?.filters?.[planningSelected.id] || [];
            const newUserFilterLibrary = rawData;
            const rawFiltersToUpdate = [];
            userFilterLibrary.forEach((filter) => {
                const filterIndex = newUserFilterLibrary.findIndex((i) => i.id === filter.id);
                if (filterIndex === -1) {
                    newUserFilterLibrary.push(filter);
                } else if (!isSameFilter(filter, newUserFilterLibrary[filterIndex])) {
                    rawFiltersToUpdate.push(filter.name);
                }
            });
            return { rawFiltersToUpdate, newUserFilterLibrary };
        },
        [userPreferences, planningSelected]
    );

    const handleViewAcceptation = async (view, accept) => {
        setLoading(true);
        try {
            await authService.acceptSharedPlanningTab(view.id, accept);
            dispatch(fetchSharedPlanningTabs(planningSelected.id));
            dispatch(fetchPlanningTabs(planningSelected.id));
            setLoading(false);
        } catch (error) {
            requestError(error, t('modal_shared_view.shared_views'));
            setLoading(false);
        }
    };

    const handleAcceptView = async (view, accept) => {
        console.log('🚀 ~ file: SharedViewModal.jsx:26 ~ handleAcceptView ~ viewId', view, accept);
        if (accept) {
            const viewData = await authService.getPlanningTab(view.id);
            const viewFiltersChange = handleViewFilterData(viewData.filters?.rawData);
            console.log('🚀 ~ file: SharedViewModal.jsx:63 ~ handleAcceptView ~ viewFiltersChange:', viewFiltersChange);
            if (viewFiltersChange.rawFiltersToUpdate.length > 0) {
                Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <span>
                            <p>{t('modal_shared_view.existing_filter')}</p>
                            <label>{t('modal_shared_view.warning_filter')}</label>
                            <List
                                size="small"
                                header={<label>{t('modal_shared_view.concerned_filters')}</label>}
                                dataSource={viewFiltersChange.rawFiltersToUpdate}
                                renderItem={(item) => <List.Item>{item}</List.Item>}
                            />
                        </span>
                    ),
                    okText: t('modal_shared_view.continue'),
                    onOk: async () => {
                        setLoading(true);
                        await dispatch(
                            updateUserPreferences({
                                filters: {
                                    ...(userPreferences?.filters || []),
                                    [planningSelected.id]: viewFiltersChange.newUserFilterLibrary,
                                },
                            })
                        );
                        await handleViewAcceptation(view, true);
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            } else {
                await dispatch(
                    updateUserPreferences({
                        filters: {
                            ...(userPreferences?.filters || []),
                            [planningSelected.id]: viewFiltersChange.newUserFilterLibrary,
                        },
                    })
                );
                await handleViewAcceptation(view, true);
            }
        }
    };

    // const onFinish = (values) => {
    //     if (onOk) {
    //         onOk(values);
    //         onCancel();
    //     }
    // };

    return (
        <Modal
            title={t('modal_shared_view.shared_views')}
            className="add-planning-modal"
            centered
            open={isVisible}
            onCancel={onCancel}
            footer={null}
        >
            <Row style={{ height: '400px' }} className="overflow-y-auto">
                <List
                    loading={loading}
                    className="w-full"
                    itemLayout="horizontal"
                    dataSource={sharedTabs}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        handleAcceptView(item, true);
                                    }}
                                >
                                    {t('general.accept')}
                                </Button>,

                                <Button
                                    type="secondary"
                                    onClick={() => {
                                        handleViewAcceptation(item, false);
                                    }}
                                >
                                    {t('general.deny')}
                                </Button>,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<></>}
                                title={<label>{item.name}</label>}
                                description={`${item.sendByUser?.firstName} ${item.sendByUser?.lastName} ${t(
                                    'modal_shared_view.wish_to_share'
                                )}.`}
                            />
                        </List.Item>
                    )}
                />
            </Row>
        </Modal>
    );
});

export default SharedViewModal;
