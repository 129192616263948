import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { Alert, Button, Col, Form, Row } from 'antd';
import WalletService from '../../services/wallet.service';

const UserDeleteForm = ({ user, showList }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const programInfo = JSON.parse(localStorage.getItem('prg'));
    const { t } = useTranslation();

    const submitForm = async () => {
        setLoading(true);
        setError('');
        try {
            if(programInfo)
                await WalletService.removeUserFromProgram(programInfo.id, {userId: user.userId});
            else
                await WalletService.deleteUser(user.userId);
            setLoading(false);
            showList();
        } catch (requestError) {
            setLoading(false);
            setError(t('error_message.error_while_deleting_user'));
        }
    };

    return (
        <Form name="user_remove_form" onFinish={submitForm}>
            <p>
             {t('notification.delete_user_confirmation')} {user.firstName} {user.lastName} ?
            </p>

            {error && <Alert message={t('general.error')} description={error} type="error" showIcon />}

            <Row>
                <Col span={24} className="text-right">
                    <Button htmlType="button" className="mr-2" onClick={showList}>
                        {t('general.cancel')}
                    </Button>
                    <Button loading={loading} type="primary" htmlType="submit">
                        {t('general.save')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

UserDeleteForm.propTypes = {
    user: PropTypes.object.isRequired,
    showList: PropTypes.func.isRequired,
};

export default UserDeleteForm;
