/* eslint-disable */

// Define migration functions

/**
 * Delete unused fields gantt_split_task and gantt_collapsed_task
 * @param {*} oldPreferences 
 * @returns new preferences with deleted gantt_split_task and gantt_collapsed_task fields
 */

export const V0ToV1 = (oldPreferences) => {
  const newPreferences = {...oldPreferences};
  if (newPreferences.gantt_split_task && newPreferences.gantt_split_task.length === 1 && newPreferences.gantt_split_task.indexOf('UNUSED') === 0) {
    delete newPreferences.gantt_split_task;
  }
  if (newPreferences.gantt_collapsed_task && newPreferences.gantt_collapsed_task.length === 1 && newPreferences.gantt_collapsed_task.indexOf('UNUSED') === 0) {
    delete newPreferences.gantt_collapsed_task
  }
  return newPreferences;
};



