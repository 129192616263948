import React from 'react';
import styled from 'styled-components';

import { PropTypes } from 'prop-types';

const PanelColors = styled.div`
    display: ${(props) => props.display ?? 'inline-block'};
    width: 15px;
    height: 15px;
    background-color: ${(props) => props.color};
`;

const ColorPreview = ({ color, display, marginClass }) => (
    <PanelColors className={`p-2 border rounded ${marginClass}`} color={color} display={display} />
);

ColorPreview.propTypes = {
    color: PropTypes.string,
    display: PropTypes.string,
    marginClass: PropTypes.string,
};

ColorPreview.defaultProps = {
    color: '#fff',
    display: null,
    marginClass: '',
};

export default ColorPreview;
