class PreferencesMigrator {
  constructor() {
    this.migrations = [];
  }

  // Add a migration function
  addMigration(version, migrationFunction) {
    this.migrations.push({ version, migrationFunction });
    // Ensure migrations are sorted in ascending order by version
    this.migrations.sort((a, b) => a.version - b.version);
  }

  // Apply migrations to an object
  /**
   * 
   * @param {*} object 
   * @param {*} targetVersion 
   * @returns 
   */
  migrateObject(object, targetVersion) {
    const currentVersion = object.version || 0;

    if (targetVersion < currentVersion) {
      throw new Error("Target version is older than the current version.");
    }

    let currentObject = { ...object }; // Create a copy of the object
    /* eslint-disable-next-line */
    for (const migration of this.migrations) {
      if (currentVersion < migration.version && migration.version <= targetVersion) {
        currentObject = migration.migrationFunction(currentObject);
        currentObject.version = migration.version;
      }
    }

    return currentObject;
  }
}

/* eslint-disable-next-line */
export {PreferencesMigrator}